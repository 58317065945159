.copy-btn {
  color: #69c;
  text-shadow: 0 0 #69c;
  cursor: pointer;
  transition: all .4s linear .4s;
}

.copy-btn:active {
  color: #070;
  text-shadow: 0 0 1px #070;
  transition: all 10ms linear 10ms;
}

label {
  color: var(--bs-primary-text-emphasis);
  font-weight: 600;
}

.form-text {
  font-weight: normal;
}
/*# sourceMappingURL=index.98f22519.css.map */
